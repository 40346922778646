.App {
  text-align: center;
  display: grid;
  grid-template-rows: 100px 1fr 50px;
}

.app-header {
  display: grid;
  grid-template-columns: auto 1fr 150px;
}

.app-logo {
  font-size: 45px;
  font-family: "Licorice", cursive;
  font-weight: 900;
  color: #ff3333;
  justify-self: center;
  align-self: center;
  background-color: white;
  margin: 0px 0px 0px 20px;
}

.app-body {
  height: calc(100vh - 150px);
  display: grid;
  grid-template-columns: 50px auto 1fr;
}

.app-moto-accent {
  display: grid;
  grid-template-rows: repeat(2, 50px);
  align-self: center;
  padding: 150px 0px 0px 30px;
}

.app-moto-accent > div:nth-child(1) {
  transform-origin: 0 0;
  transform: rotate(270deg);
  font: 15px 'roboto';
  font-weight: 100;
  color: #ff2b2a;
  align-self: right;
  width: 100px;
  height: 20px;
}

.app-moto-accent > div:nth-child(2) {
  transform-origin: 0 0;
  transform: rotate(270deg);
  font: bold 15px 'roboto';
  color: #ff2b2a;
  width: 100px;
  height: 20px;
}

.app-moto {
  display: grid;
  grid-template-rows: 200px 1fr auto 132px;
  padding: 80px 0px 0px 200px;
}

.app-moto-sub1 {
   justify-self: left;
   font: 38px 'Roboto';
   width: 400px;
   height: 200px;
   color: #47555d;
   text-align: left;
   font-weight: 400;
}

.app-moto-sub2 {
  font: 28px 'Roboto';
  font-weight: 100;
  color: #47555d;
  text-align: left;
  width: 400px;
}

.app-moto-newsletter-added {
  width: 380px;
  height: 40px;
  align-self: top;
  position: relative;
  background-color: whitesmoke;
  border-radius: 4px;
  color: #ff2b2a;
  font-size: 16px;
  font-weight: bold;
  padding-top: 10px;
}

.app-moto-newsletter {
  height: 50px;
  align-self: top;
  position: relative;
}

.app-moto-newsletter > form > input {
  height: 50px;
  background-color: whitesmoke;
  border: none;
  width: 380px;
  font: 14px 'roboto';
  padding-left: 15px;
  color: #47555d;
  
}

.app-moto-newsletter > form > input:focus {
  border-style: none;
  background-color: gainsboro;
  color: black;
  outline: none;
}

.app-moto-newsletter > form > input:focus::placeholder {
  color: transparent;
}

.btn-app-moto-newsletter {
  height: 30px;
  border-radius: 4px;
  width: 100px;
  position: absolute;
  top: 5px;
  right: 10px;
  background-color: #ff2b2a;
  font: bold 14px 'roboto';
  text-align: center;
  color: white;
  cursor: pointer;
  padding-top: 13px;
  text-decoration: none;
}

.app-moto-newsletter-hint {
  font: normal 16px 'roboto';
  font-weight: 500;
  text-align: left;
  height: 30px;
  padding-left: 5px;
  color: #47555d;
}

.app-moto-logo {
  padding: 100px 20px 0px 0px;
  background-image: url('logo.png');
  background-repeat: no-repeat;
  background-size: 80%;
  background-position: center center;
}

.app-footer {
  display: grid;
}

.app-copyright {
  align-self: center;
  justify-self: center;
  font: normal 12px 'Roboto';
  color: #ff3333
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
